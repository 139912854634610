<template>
  <div class="Partners">
    <marquee-text :duration="60">
      <div class="Partners-Wrapper">
        <div v-for="item in paymentsLogos" :key="item.name" class="Partners-Partner">
          <img :src="item.images.tiny" class="Partners-Image" loading="lazy" alt="">
        </div>
      </div>
    </marquee-text>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';
import { mapState } from 'vuex';

export default {
  name: 'Partners',
  components: {
    MarqueeText,
  },
  computed: {
    ...mapState(['paymentsLogos']),
  },
};
</script>

<style lang="scss">
.Partners {
  padding: 20px 0;
  border-bottom: 1px solid rgba(110, 88, 131, 0.3);

  @media(min-width: $screen-m) {
    padding-bottom: 40px;
  }

  @media(min-width: $screen-l) {
    padding-bottom: 25px;
  }

  &-Wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  &-Partner {
    flex-shrink: 2;
    margin-right: 10px;
    margin-bottom: 10px;

    @media(min-width: $screen-m) {
      margin-right: 25px;
      margin-bottom: 15px;
    }
  }
}
</style>
