export default {
  methods: {
    openLogin() {
      window.openLogin();
    },
    openRegistration() {
      window.openRegistration();
    },
  },
};
