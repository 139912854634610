<template>
  <div id="app">
    <MainNav v-if="!countryNotAllowed" :key="$i18n.locale" />
    <router-view />
    <Footer v-if="!countryNotAllowed" />
    <CookiesPopup />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import MainNav from '@/components/MainNav.vue';
import Footer from '@/components/Footer.vue';
import CookiesPopup from '@/components/CookiesPopup.vue';
import {
  sids, domainProd, domainProdDe, domainProdDeWWW, domainStage, domainDev, notAllowedCountries,
} from '@/config';
import createAuthScript from '@/mixins/createAuthScript';
import detect from '@/utils/deviceDetector';

const Cookie = require('js-cookie');

export default {
  name: 'App',
  mixins: [createAuthScript],
  components: {
    CookiesPopup,
    MainNav,
    Footer,
  },
  computed: {
    ...mapState(['country', 'skin', 'license', 'countryNotAllowed', 'platform']),
  },
  created() {
    this.getUserCountry().then(() => {
      if (notAllowedCountries.includes(this.country)) {
        this.setCountryNotAllowed();
        if (this.$route.path !== '/not-allowed') this.$router.push('/not-allowed');
        return;
      }

      const country = this.country.toLowerCase();
      const langFromParam = window.location.pathname.split('/')[1];
      let domain;

      if (process.env.VUE_APP_MODE === 'production') {
        if (window.location.origin === domainProdDe) domain = domainProdDe;
        else if (window.location.origin === domainProdDeWWW) domain = domainProdDeWWW;
        else domain = domainProd;
      } else if (process.env.VUE_APP_MODE === 'stage') domain = domainStage;
      else domain = domainDev;

      if (sids[country]) {
        if (window.location.origin !== domain || langFromParam !== country) {
          window.location = `${domain}/${country}`;
        } else {
          this.setSid(sids[country]);
        }
      }

      if (sids[langFromParam]) {
        this.setSid(sids[langFromParam]);
        this.setCountry(langFromParam.toUpperCase());
      }

      if (sids.default.languages[country] && window.location.origin !== domain) {
        window.location = `${domain}/${country}`;
      }

      if (!this.$i18n.availableLocales.includes(this.$i18n.locale)) {
        this.$i18n.locale = 'en';
      }

      const lang = this.$i18n.locale === 'gb' ? 'en' : this.$i18n.locale;

      this.detectMobile();
      this.createConfigScript();
      this.getGames(`appName=${this.skin}&is_most_popular=true`);
      this.getJackpots(`skin=${this.skin}&country=${this.country}&lang=${lang}`);
      this.getPaymentsLogos(`skin=${this.skin}&country=${this.country}&lang=${lang}`);
      this.createAuthScript();
    });
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
    if (Cookie.get('seenCookiesPopup')) this.setNeedsCookiesPopup(false);
  },
  methods: {
    ...mapMutations(['setWidth', 'setSid', 'setPlatform', 'setCountry', 'setCountryNotAllowed', 'setNeedsCookiesPopup']),
    ...mapActions(['getUserCountry', 'getLicensy', 'getJackpots', 'getGames', 'getLogosMarkup', 'getPaymentsLogos']),
    updateWidth() {
      this.setWidth(window.innerWidth);
    },
    detectMobile() {
      if (detect.mobile() || detect.tablet() || detect.phone()) this.setPlatform('mobile');
    },
    createConfigScript() {
      window.SON_CONFIG = {
        skin: this.skin,
        license: this.license,
        country: this.country,
        domain: window.location.host,
        device: this.platform,
        lang: this.$i18n.locale,
        dynid: 'no_zone',
        zoneid: 'none',
      };

      // eslint-disable-next-line func-names
      (function () {
        const s1 = document.createElement('script');
        const s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = `https://service-dev.image-tech-storage.com/webcomponents/webcomponents.js?v=${(new Date()).getTime()}`;
        s0.parentNode.insertBefore(s1, s0);
      }());
    },
  },
};
</script>

<style lang="scss">
:root {
  --color-main1: #FE863A;
  --color-main2: #8C75FA;
  --color-main3: #FFA11D;
  --color-faded: rgba(255, 255, 255, 0.5);
  --color-more-faded: rgba(255, 255, 255, 0.4);
  --color-most-faded: rgba(255, 255, 255, 0.2);
  --color-text-main: #fff;
  --license-text-size: 14px;
  --license-text-color: var(--color-more-faded);
  --license-text-link-color: var(--color-text-main);
  --license-text-link-decoration: none;
  --license-text-link-hover-color: var(--color-main2);
  --license-text-link-hover-decoration: none;
  --color-text-ghost: #9DA3B4;
  --color-border-ghost: #272B5F;
  --color-bg: rgba(39, 43, 95, 0.2);
  --color-bg-hover: #242857;
  --color-bg-darker: rgba(39, 43, 95, 0.5);
  --color-bg-nav: #151841;
  --color-form: #1E1A46;
  --color-body: #10133A;
  --beam-background: 'transparent';
}
</style>
