export default {
  methods: {
    createAuthScript() {
      // eslint-disable-next-line no-unused-vars
      const SON_API = {
        modules: [
          {
            element: 'son_modal',
            modules: ['login', 'registration', 'forgot-password'],
            type: 'modal',
            onInitialized() {
              if (this.$route.query.NeoDL === 'Registration') {
                this.openRegistration();
              }
            },
          },
          {
            element: 'son_embeded',
            modules: ['login'],
            type: 'embeded',
          },
        ],
        affiliate: 'AFFILIATE_NAME',
        dynid: 'DYN_ID',
        zoneid: 'ZONE_ID',
        display_name: 'VegasWinner',
        email: 'Support@VegasWinner.com',
      };
      const s1 = document.createElement('script');
      const s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.id = 'authScript';
      s1.src = `https://auth.netdnstrace1.com/00c77e63-5e92-4a74-8e05-4154e24e0834/default.js?lang=${this.$i18n.locale}`;
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    },
  },
};
