export const apiHost = 'https://games.netdnstrace1.com';
export const serviceHost = 'https://service.safe-communication.com';
export const contentHost = 'https://www.playeroffers.com';

export const domainProd = 'https://www.vegaswinner.com';
export const domainProdDe = 'https://vegaswinner.de';
export const domainProdDeWWW = 'https://www.vegaswinner.de';
export const domainStage = 'http://vegaswinner.dev.wakeapp.ru';
export const domainDev = 'http://localhost:8081';

export const notAllowedCountries = ['BE', 'CZ', 'EE', 'FR', 'GR', 'GL', 'LT', 'RS', 'SG', 'SK', 'TR', 'US', 'ES', 'PT', 'MX', 'CH', 'DE-SH', 'RU'];

export const sids = {
  default: {
    skin: 'VegasWinner',
    license: 'MT',
    token: '7f73ab615f196b7f63b49fbdbbee82eb',
    locale: 'en',
    languages: {
      en: 'English',
      de: 'Deutsch',
      no: 'Norsk',
      fi: 'Suomi',
    },
  },
  gb: {
    skin: 'VegasWinner.co.uk',
    license: 'UK',
    token: 'cbcb58ac2e496207586df2854b17995f',
    locale: 'en',
  },
  dk: {
    skin: 'VegasWinner.dk',
    license: 'DK',
    token: 'be28eebbdbf94b36f7b691f516ba37f7',
    locale: 'dk',
  },
  at: {
    skin: 'VegasWinner',
    license: 'MT',
    token: '7f73ab615f196b7f63b49fbdbbee82eb',
    locale: 'de',
  },
  se: {
    skin: 'VegasWinner.se',
    license: 'SE',
    token: '40a552262eb7757362c24eb96fe0780c',
    locale: 'se',
  },
};

export const countries = [
  'af',
  'ax',
  'al',
  'dz',
  'as',
  'ad',
  'ao',
  'ai',
  'aq',
  'ag',
  'ar',
  'am',
  'aw',
  'au',
  'at',
  'az',
  'bh',
  'bs',
  'bd',
  'bb',
  'by',
  'be',
  'bz',
  'bj',
  'bm',
  'bt',
  'bo',
  'bq',
  'ba',
  'bw',
  'bv',
  'br',
  'io',
  'bn',
  'bg',
  'bf',
  'bi',
  'kh',
  'cm',
  'ca',
  'cv',
  'ky',
  'cf',
  'td',
  'cl',
  'cn',
  'cx',
  'cc',
  'co',
  'km',
  'cg',
  'cd',
  'ck',
  'cr',
  'ci',
  'hr',
  'cu',
  'cw',
  'cy',
  'cz',
  'dk',
  'dj',
  'dm',
  'do',
  'ec',
  'eg',
  'sv',
  'gq',
  'er',
  'ee',
  'et',
  'fk',
  'fo',
  'fj',
  'fi',
  'fr',
  'gf',
  'pf',
  'tf',
  'ga',
  'gm',
  'ge',
  'de',
  'gh',
  'gi',
  'gr',
  'gl',
  'gd',
  'gp',
  'gu',
  'gt',
  'gg',
  'gn',
  'gw',
  'gy',
  'ht',
  'hm',
  'va',
  'hn',
  'hk',
  'hu',
  'is',
  'in',
  'id',
  'ir',
  'iq',
  'ie',
  'im',
  'il',
  'it',
  'jm',
  'jp',
  'je',
  'jo',
  'kz',
  'ke',
  'ki',
  'kp',
  'kr',
  'kw',
  'kg',
  'la',
  'lv',
  'lb',
  'ls',
  'lr',
  'ly',
  'li',
  'lt',
  'lu',
  'mo',
  'mk',
  'mg',
  'mw',
  'my',
  'mv',
  'ml',
  'mt',
  'mh',
  'mq',
  'mr',
  'mu',
  'yt',
  'mx',
  'fm',
  'md',
  'mc',
  'mn',
  'me',
  'ms',
  'ma',
  'mz',
  'mm',
  'na',
  'nr',
  'np',
  'nl',
  'nc',
  'nz',
  'ni',
  'ne',
  'ng',
  'nu',
  'nf',
  'mp',
  'no',
  'om',
  'pk',
  'pw',
  'ps',
  'pa',
  'pg',
  'py',
  'pe',
  'ph',
  'pn',
  'pl',
  'pt',
  'pr',
  'qa',
  're',
  'ro',
  'ru',
  'rw',
  'bl',
  'sh',
  'kn',
  'lc',
  'mf',
  'pm',
  'vc',
  'ws',
  'sm',
  'st',
  'sa',
  'sn',
  'rs',
  'sc',
  'sl',
  'sg',
  'sx',
  'sk',
  'si',
  'sb',
  'so',
  'za',
  'gs',
  'ss',
  'es',
  'lk',
  'sd',
  'sr',
  'sj',
  'sz',
  'se',
  'ch',
  'sy',
  'tw',
  'tj',
  'tz',
  'th',
  'tl',
  'tg',
  'tk',
  'to',
  'tt',
  'tn',
  'tr',
  'tm',
  'tc',
  'tv',
  'ug',
  'ua',
  'ae',
  'gb',
  'us',
  'um',
  'uy',
  'uz',
  'vu',
  've',
  'vn',
  'vg',
  'vi',
  'wf',
  'eh',
  'ye',
  'zm',
  'zw',
];
