export function searchInGamesRes(games, query) {
  return games.filter((game) => {
    const str = query.trim().toLowerCase();
    const title = game.item_title.toLowerCase();
    const isMobileVersion = title.includes('mobile') || title.includes('touch');
    return title.includes(str) && !isMobileVersion;
  });
}

export function formatNumber(number) {
  return Math.floor(number).toString().replace(/(\d{1,3})(?=((\d{3})*([^\d]|$)))/g, ' $1 ');
}

export function detectDoubleGames(games) {
  const set = new Set();
  return Object.values(games).filter((game) => {
    const gamesWithoutPics = ['Blueprint King', 'Millionaire Genie', 'Firework Fever', 'RedTiger Mega Jackpot', 'EGT Level Total'];
    if (gamesWithoutPics.includes(game.name)) return null;
    const regexp = /Mobile|Touch/;
    const gameTitle = game.name.replace(regexp, '').trim();
    if (!set.has(gameTitle)) {
      set.add(gameTitle);
      return gameTitle;
    }
    return null;
  });
}
