<template>
  <!-- eslint-disable max-len -->
  <section class="Footer">
    <Partners class="Footer-Partners"/>
    <nav class="Nav Footer-Nav">
      <LocalizedLink class="Link Footer-Link" to="/payment-methods">
        {{ $t('menu.paymentMethods') }}
      </LocalizedLink>
      <LocalizedLink class="Link Footer-Link" to="/responsible-gamings">
        {{ $t('menu.responsibleGaming') }}
      </LocalizedLink>
      <LocalizedLink class="Link Footer-Link" to="/terms-and-conditions">
        {{ $t('menu.terms') }}
      </LocalizedLink>
      <LocalizedLink v-if="$i18n.locale !== 'se'" class="Link Footer-Link" to="/bonus-policy">
        {{ $t('menu.bonusPolicy') }}
      </LocalizedLink>
      <LocalizedLink class="Link Footer-Link" to="/privacy-policy">
        {{ $t('menu.privacyPolicy') }}
      </LocalizedLink>
      <LocalizedLink class="Link Footer-Link" to="/about-us">
        {{ $t('menu.aboutUs') }}
      </LocalizedLink>
      <LocalizedLink v-if="$i18n.locale !== 'dk'" class="Link Footer-Link" to="/faq">
        {{ $t('menu.faq') }}
      </LocalizedLink>
      <a v-if="$i18n.locale === 'uk'" href="https://chillipartners.com/" class="Link Footer-Link">
        {{ $t('affiliates') }}
      </a>
    </nav>
    <div class="Footer-Disclaimer">
      <div class="Footer-Text">
        <son-license-text v-pre>
          <a slot="responsible-link" href="" target="_blank"></a>
        </son-license-text>
        <LocalizedLink v-if="$i18n.locale === 'gb'" class="Footer-Text Footer-TextLink" to="/responsible-gamings">
          {{ $t('footerText') }}
        </LocalizedLink>
        <son-beam v-pre></son-beam>
      </div>
    </div>
    <div
      class="Footer-Logos"
    >
      <son-license-logos v-pre></son-license-logos>
    </div>
  </section>
</template>

<script>
import Partners from '@/components/Partners.vue';
import LocalizedLink from '@/components/LocalizedLink.vue';

export default {
  name: 'Footer',
  components: {
    Partners,
    LocalizedLink,
  },
};
</script>

<style lang="scss">
.Footer {
  margin-bottom: 30px;
  text-align: center;

  &-Partners {
    margin-bottom: 28px;

    @media(min-width: $screen-l) {
      margin-bottom: 36px;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 46px;
    }
  }

  &-Nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;
  }

  &-Link {
    margin-right: 31px;
    margin-bottom: 16px;
    font-size: 12px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    @media(min-width: $screen-m) {
      margin-right: 28px;
      font-size: 10px;
    }

    @media(min-width: $screen-l) {
      margin-right: 28px;
      font-size: 13px;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  &-Disclaimer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  &-Text {
    order: 1;
    font-size: 14px;
    line-height: 1.66;
    color: var(--color-more-faded);
    margin-bottom: 5px;

    @media(min-width: $screen-m) {
      order: 0;
    }
  }

  &-Icons {
    display: flex;
    margin-left: 0;
    margin-bottom: 20px;

    @media(min-width: $screen-m) {
      margin-left: 141px;
      margin-bottom: 0;
    }
  }

  &-Icon {
    margin-right: 21px;

    &:last-child {
      margin-right: 0;
    }
  }

  &-IconFilter {
    width: 70px;
    filter: saturate(0.5) brightness(0.4);

    &:hover {
      filter: none;
    }
  }

  &-TextLink {
    color: var(--color-text-main);
  }

  &-Logos {
    display: inline-flex;
    align-items: center;
  }
}

</style>
