<template>
  <router-link :to="getTo()">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: 'LocalizedLink',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    getTo() {
      if (typeof this.to !== 'string' || !this.$route.params.locale) {
        return this.to;
      }
      const { locale } = this.$route.params;
      return `/${locale}/${this.to.replace(/^\/|\/$/g, '')}`;
    },
  },
};
</script>
