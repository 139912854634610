import Vue from 'vue';
import VueRouter from 'vue-router';
import { sids, countries } from '@/config';
import i18n from '@/plugins/i18n';
import store from '@/store';

export default function getRouter(lang) {
  const HomePage = () => import(/* webpackChunkName: "homePage" */ '@/pages/HomePage.vue');
  const SupportPage = () => import(/* webpackChunkName: "supportPage" */ '@/pages/SupportPage.vue');
  const BonusPolicy = () => import(/* webpackChunkName: "bonusPolicy" */ '@/pages/BonusPolicy.vue');
  const PrivacyPolicy = () => import(/* webpackChunkName: "privacyPolicy" */ '@/pages/PrivacyPolicy.vue');
  const AboutUs = () => import(/* webpackChunkName: "aboutUs" */ '@/pages/AboutUs.vue');
  const VIPClub = () => import(/* webpackChunkName: "vipClub" */ '@/pages/VIPClub.vue');
  const page404 = () => import(/* webpackChunkName: "page404" */ '@/pages/Page404.vue');
  const NotAllowed = () => import(/* webpackChunkName: "notAllowed" */ '@/pages/NotAllowed.vue');
  const Faq = () => import(/* webpackChunkName: "faqPage" */ '@/pages/FaqPage.vue');
  const PaymentMethods = () => import(/* webpackChunkName: "paymentMethods" */ '@/pages/PaymentMethods.vue');
  const TermsConditions = () => import(/* webpackChunkName: "termsConditions" */ '@/pages/TermsConditions.vue');
  const ResponsibleGamings = () => import(/* webpackChunkName: "responsibleGamings" */ '@/pages/ResponsibleGamings.vue');
  const UploadYourDocuments = () => import(/* webpackChunkName: "uploadYourDocuments" */ '@/pages/UploadYourDocuments.vue');

  Vue.use(VueRouter);

  const routes = [
    {
      path: '/not-allowed',
      name: 'NotAllowed',
      component: NotAllowed,
    },
    {
      path: '/',
      redirect: lang,
    },
    {
      path: '/:locale',
      component: {
        template: '<router-view></router-view>',
      },
      beforeEnter: (to, from, next) => {
        const { locale } = to.params;

        if (!sids[locale] && !sids.default.languages[locale]) {
          if (countries.includes(locale)) return next('/en');
          return next('/en/404');
        }
        return next();
      },
      children: [
        {
          path: '',
          name: 'HomePage',
          component: HomePage,
        },
        {
          path: 'bonus-policy',
          name: 'BonusPolicy',
          component: BonusPolicy,
        },
        {
          path: 'privacy-policy',
          name: 'PrivacyPolicy',
          component: PrivacyPolicy,
        },
        {
          path: 'about-us',
          name: 'AboutUs',
          component: AboutUs,
        },
        {
          path: 'vip',
          component: VIPClub,
          name: 'VIPClub',
          beforeEnter: (to, from, next) => {
            if (i18n.locale === 'se') return next('/404');
            return next();
          },
        },
        {
          path: 'payment-methods',
          name: 'PaymentMethods',
          component: PaymentMethods,
        },
        {
          path: 'terms-and-conditions',
          name: 'TermsConditions',
          component: TermsConditions,
        },
        {
          path: 'responsible-gamings',
          name: 'ResponsibleGamings',
          component: ResponsibleGamings,
        },
        {
          path: 'faq',
          name: 'Faq',
          component: Faq,
          beforeEnter: (to, from, next) => {
            if (i18n.locale === 'dk') return next('/404');
            return next();
          },
        },
        {
          path: 'support',
          name: 'support',
          component: SupportPage,
          beforeEnter: (to, from, next) => {
            if (i18n.locale === 'dk') return next('/404');
            return next();
          },
        },
        {
          path: 'upload-your-documents',
          name: 'UploadYourDocuments',
          component: UploadYourDocuments,
        },
        {
          path: '*',
          name: 'NotFound',
          component: page404,
        },
      ],
    },
  ];

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to) {
      if (to.hash) {
        return { selector: to.hash };
      }
      return {
        x: 0,
        y: 0,
      };
    },
    routes,
  });

  router.beforeEach((to, from, next) => {
    if (to.path !== '/not-allowed' && (store.state.countryNotAllowed)) {
      return next('/not-allowed');
    }

    return next();
  });

  return router;
}
