import '@/styles/main.scss';
import Vue from 'vue';
import { Plugin } from 'vue-fragment';
import vClickOutside from 'v-click-outside';
import VModal from 'vue-js-modal';
import Meta from 'vue-meta';
import App from './App.vue';
import store from './store';
import getRouter from './router';
import i18n from './plugins/i18n';

i18n.then((i18nData) => {
  Vue.use(Plugin);
  Vue.use(vClickOutside);
  Vue.use(VModal);
  Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true,
  });

  const urlParams = new URLSearchParams(window.location.search);
  const cookies = {
    Aname: urlParams.get('aname'),
    Zone_id: urlParams.get('zone_id'),
    Dyn_id: urlParams.get('dyn_id'),
  };
  const setCookie = (item) => {
    document.cookie = `${item[0]}=${decodeURIComponent(item[1])}`;
  };

  Object.entries(cookies).map((item) => setCookie(item));

  new Vue({
    router: getRouter(i18nData.locale),
    store,
    i18n: i18nData,
    render: (h) => h(App),
  }).$mount('#app');
});
