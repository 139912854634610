<template> <!-- eslint-disable max-len -->
  <nav class="MainNav" :class="{'MainNav--bg': documentIsScrolled}">
    <div class="MainNav-Overlay" v-if="navIsOpen" @click="navIsOpen = !navIsOpen"></div>
    <div class="MainNav-TopBar">
      <div v-if="$i18n.locale === 'dk'" class="MainNav-ResponsibleDK 18+">
        <a href="https://www.spillemyndigheden.dk/" class="MainNav-ResponsibleDKLink">
          <img src="@/assets/img/spillemyndigheden.svg" alt="spillemyndigheden"
               class="MainNav-ResponsibleIcon">
          <span>18+</span>
        </a>
        <a href="https://www.stopspillet.dk/" class="MainNav-ResponsibleDKLink">StopSpillet.dk</a>
        <a href="tel:+4570222825" class="MainNav-ResponsibleDKLink">+45 70 22 28 25</a>
        <div class="MainNav-ResponsibleDKText">Udeluk dig via</div>
        <a href="https://www.rofus.nu/" class="MainNav-ResponsibleDKLink"><span>ROFUS</span>.nu</a>
        <button class="MainNav-ResponsibleDKLink" @click="show">
          Ansavarligt.spill
        </button>
      </div>
      <div v-else class="MainNav-Responsible">
        <div v-if="$i18n.locale === 'se'" class="MainNav-ResponsibleSE">
          <a href="https://www.spelpaus.se/?scos=true"
             class="MainNav-ResponsibleSELink">
            <img src="@/assets/img/spelpaus.png" alt="spelpaus" class="MainNav-ResponsibleSEIcon">
          </a>
          <a href="http://son-direct.com/?appName=VegasWinner.se&langID=14&name=self_test"
             class="MainNav-ResponsibleSELink">
            <img src="@/assets/img/sjalvtest.png" alt="sjalvtest" class="MainNav-ResponsibleSEIcon">
          </a>
          <button class="MainNav-ResponsibleSELink" @click="openRegistration()">
            <img src="@/assets/img/spelgranser.png" alt="spelgranser"
                 class="MainNav-ResponsibleSEIcon">
          </button>
        </div>
        <div v-if="$i18n.locale !== 'dk'" class="MainNav-ResponsibleAll">
          <img src="@/assets/img/18+.png" alt="18+" class="MainNav-ResponsibleIcon">
          <div class="MainNav-ResponsibleText">
            {{ $t('playResponsibly.title') }}
          </div>
          <LocalizedLink v-if="$i18n.locale !== 'se'" class="Nav-Name MainNav-Link MainNav-ResponsibleLink"
                         to="/responsible-gamings">
            {{ $t('playResponsibly.linkText') }}
          </LocalizedLink>
          <a v-if="$i18n.locale === 'se'" href="https://www.stodlinjen.se/#!/"
             class="Nav-Name MainNav-Link MainNav-ResponsibleLink">
            {{ $t('playResponsibly.linkText') }}
          </a>
          <a href="https://www.begambleaware.org/" v-if="$i18n.locale === 'gb'" class="MainNav-Link MainNav-ResponsibleUKImg">
            <img src="@/assets/img/begambleawareorg.png" alt="begambleawareorg icon">
          </a>
        </div>
      </div>
      <div class="MainNav-Content">
        <div class="MainNav-Nav" :class="{'MainNav-Nav--de': $i18n.locale === 'de' || $i18n.locale === 'fi'}">
          <div class="MainNav-Toggle" @click="navIsOpen = !navIsOpen"
               :class="{'MainNav-Toggle--de': $i18n.locale === 'de' || $i18n.locale === 'no'}"
          >
            <img class="MainNav-Logo" src="@/assets/img/toggle.svg"/>
          </div>
          <LocalizedLink class="MainNav-Logo" to="/">
            <picture>
              <source media="(max-width: 589px)" :srcset="require('@/assets/img/logo_mobile.svg')">
              <img
                class="MainNav-Logo"
                :src="require('@/assets/img/logo.svg')"
                loading="lazy"
                alt="VegasWinner">
            </picture>
          </LocalizedLink>
        </div>
        <div class="Nav MainNav-Links">
          <LocalizedLink v-if="$i18n.locale !== 'se'" class="Nav-Name MainNav-Link" to="/#promotions">
            {{ $t('menu.promotions') }}
          </LocalizedLink>
          <LocalizedLink v-if="$i18n.locale !== 'se'" class="Nav-Name MainNav-Link" to="/vip">
            {{ $t('menu.vip') }}
          </LocalizedLink>
        </div>
        <div class="MainNav-Login">
          <button
            class="Btn Btn--text MainNav-Btn MainNav-Btn--text"
            @click="openLogin()"
          >
            {{ $t('menu.login') }}
          </button>
          <button
            class="Btn Btn--outline Btn--outline1 MainNav-Btn"
            :class="{'MainNav-Btn--de': $i18n.locale === 'de'}"
            @click="openRegistration()"
          >
            {{ $t('menu.register') }}
          </button>
        </div>
        <LanguageSwitcher class="MainNav-Language"/>
      </div>
    </div>
    <transition name="slide-left">
      <div v-show="navIsOpen" class="AsideMenu MainNav-Aside">
        <div class="AsideMenu-Header">
          <div class="AsideMenu-Close" @click="navIsOpen = !navIsOpen"></div>
          <picture>
            <source media="(max-width: 589px)" :srcset="require('@/assets/img/logo_mobile.svg')">
            <img
              class="MainNav-Logo"
              :src="require('@/assets/img/logo.svg')"
              loading="lazy"
              alt="VegasWinner">
          </picture>
        </div>
        <div class="AsideMenu-Form">
          <div id="son_embeded"></div>
        </div>
        <div class="AsideMenu-List">
          <NavItem :items="localizedNavItems" @click="navIsOpen = !navIsOpen"/>
        </div>
      </div>
    </transition>

    <modal name="modal"
       :scrollable="true"
       :reset="true"
       :adaptive="true"
       :styles="{
         'overflowY': 'auto',
         'borderRadius': '10px',
       }"
       :shiftY="0.05"
       :shiftX="0.5"
       class="modal"
    >
      <div class="Modal-Close" @click="hide"></div>
      <h2 class="Title Title--type-h2 Modal-Title Colored">Ansvarligt Spil</h2>
      <div class="Modal-List">
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            VI OPFORDRER DIG TIL AT SPILLE ANSVARLIGT
          </h4>
          <p class="Modal-Text">
            Hasardspil er underholdning, og du skal derfor spille ansvarligt. Det er ikke en
            måde at få lettjente
            penge på. Du skal altid undgå at jagte dine tab, aldrig spille for penge du
            ikke kan undvære, og aldrig
            spille for penge der skulle have været brugt til husleje, regninger eller andre
            udgifter. Du bør løbende
            kontrollere din aktivitet på Saldo-siden, så du hele tiden ved, hvor mange penge
            du har brugt.
          </p>
          <p class="Modal-Text">
            Hvis det på nogen måde bliver ubehageligt for dig at spille, skal du altid holde
            en
            pause fra dit spil
            eller måske endda overveje selvudelukkelse.
          </p>
          <p class="Modal-Text">
            Hvis du har spørgsmål eller bekymringer, er du velkommen til at kontakte
            kundeservice,
            som arbejder 24/7,
            og altid er klar til at hjælpe dig.
          </p>
          <p class="Modal-Text">
            Den danske spillemyndighed har en offentlig hjemmeside, der indeholder
            oplysninger
            om
            spilleafhængighed.
            Vi opfordrer dig til at besøge siden her –
            <a href="www.stopspillet.dk" class="Modal-ItemLink">www.stopspillet.dk</a>
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Ansvarligt spil og beskyttelsesforanstaltninger
          </h4>
          <p class="Modal-Text">
            Det er vigtigt, at vores kunder hygger sig i casinoet, men vi ved også godt, at
            gaming kan blive et
            problem for nogen spillere.
          </p>
          <p class="Modal-Text">
            Vi er stolte af at være retfærdige, gennemskuelige og socialt ansvarlige, og vi
            vil gerne beskytte
            vores spillere mod at spille uansvarligt. Vi vil gerne beskytte dig, så du hygge
            dig med at spille og
            stadig få en ansvarlig spilleoplevelse. Derfor har du også mulighed for at
            oprette
            indbetalingsgrænser
            eller hele spillegrænser, så du kan styre dit spil inde på Min konto på
            casinoet.
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Rofus
          </h4>
          <p class="Modal-Text">
            Rofus er en portal, som du kan melde dig til enten midlertidigt eller permanent,
            og når du først er
            tilmeldt, kan du ikke længere spille på eller modtage markedsføringsmateriale
            fra
            hjemmesider, som har
            en lovlig licens i Danmark. Hvis du selvudelukker dig, opfordrer vi dig til at
            overveje, om du også
            skal lade dig selvudelukke på Rofus. For yderligere oplysninger se
            <a href="www.spillemyndigheden.dk/rofus" class="Modal-ItemLink">www.spillemyndigheden.dk/rofus</a>
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Afkøling, Midlertidig Udelukkelse og Selvudelukkelse
          </h4>
          <p class="Modal-Text">
            Hvis du har behov for en pause fra at spille, og du ikke stoler på din egen evne
            til at holde dig væk,
            så kan du bruge vores muligheder for spillerbegrænsninger. Du finder dem på
            siden
            Spillerbegrænsninger
            under "Min konto".
          </p>
          <p class="Modal-Text">
            Du kan vælge mellem mange forskellige spillerbegrænsninger:
          </p>
          <ul class="Modal-InnerList">
            <li class="Modal-InnerItem">
              <p class="Modal-Text">
                Du kan vælge Afkøling, hvor du har følgende valgmuligheder: 24 timer, 48
                timer og 7 dage.
              </p>
            </li>
            <li class="Modal-InnerItem">
              <p class="Modal-Text">
                Du kan vælge Midlertidig Udelukkelse, hvor du har følgende valgmuligheder:
                30
                dage, 60 dage og 90
                dage.
              </p>
            </li>
            <li class="Modal-InnerItem">
              <p class="Modal-Text">
                Du kan vælge Selvudelukkelse, som betyder, at din konto ikke kan aktiveres
                igen,
                medmindre du selv
                beder om det, og du kan ikke bede om det inden for de første 365 dage fra
                din
                selvudelukkelse. For
                at forlænge perioden skal du kontakte Support, når du har oprettet din
                selvudelukkelse.
              </p>
            </li>
          </ul>
          <p class=" Modal-Text">
            Under selvudelukkelse kan du ikke spille eller indbetale, og du modtager heller ikke noget
            markedsføringsmateriale (der kan gå op til 24 timer, inden du er slettet fra alle
            markedsføringsdatabaser).
          </p>
          <p class=" Modal-Text">
            Bemærk venligst, at selvudelukkelse ikke gælder for andre konti, som du har under vores licens.
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Implementering af indbetalingsgrænser
          </h4>
          <p class=" Modal-Text">
            Når du opretter en konto under vores danske licens, skal du indstille daglige,
            ugentlige og månedlige indbetalingsgrænser, inden du kan spille.
          </p>
          <p class=" Modal-Text">
            Hvis du øger indbetalingsgrænserne, træder de i kraft efter 24 timer, men hvis du sætter dem ned,
            gælder de med det samme.
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Realitetstjek
          </h4>
          <p class=" Modal-Text">
            Hver gang du har været logget ind i 60 minutter, popper der en besked op på din skærm, der fortæller
            dig, hvor længe du har været logget ind, hvor meget du har spillet for, og hvor meget du har vundet.
            Beskeden giver dig mulighed for at trykke på Log ud-knappen, hvilket logger dig ud.
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Selv-evaluering
          </h4>
          <p class=" Modal-Text">
            En selv-evalueringstest er præcis det, navnet antyder... en test hvor du kan finde ud af, om dit
            spil er ved at blive et problem for dig. Ved at tage selv-evalueringstest finder du hurtigt ud af,
            om dit spil er blevet et problem for dig, og du kan derfor gøre noget ved det, inden det tager
            overhånd.
          </p>
          <p class=" Modal-Text">
            Hvis du er bekymret for dit spil, eller du bare gerne vil have styr på det, kan du
            <a href="https://son-email-direct.com/?skin=vw.dk&language=DA&name=self_test" class="Modal-ItemLink">klikke
              her</a> og
            tage selv-evalueringstesten
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Vi holder et vågent øje
          </h4>
          <p class=" Modal-Text">
            Vi har bemærket tegn på, at du måske har et spilleproblem. Vi kontakter dig omkring vores Ansvarligt
            Spil-politik, for at drøfte om du bør oprette indbetalingsgrænser eller andre begrænsninger.
          </p>
          <p class=" Modal-Text">
            Du kan også se oplysningerne på nedenstående hjemmesider, som muligvis kan give dig støtte og råd,
            så du kan løse dine spilleproblemer:
          </p>
          <div class="Modal-ItemLinks">
            <a href="https://www.alfakbh.dk/" class="Modal-ItemLink">
              Alfa København
            </a>
            <br>
            <a href="https://www.tjele.com/andre-misbrug/ludomani/ludomani-i-kobenhavn/" class="Modal-ItemLink">
              Behandlingscenter Tjele København
            </a>
            <br>
            <a href="https://ludomani.dk/" class="Modal-ItemLink">
              Center for Ludomani København
            </a>
            <br>
            <a href="https://gadegaard-ludomanibehandling.dk/" class="Modal-ItemLink">
              Gadegaard Ludomanibehandling
            </a>
            <br>
            <a href="https://mwklinik.dk/ludomaniklinikken/" class="Modal-ItemLink">
              Ludomaniklinikken Mindwork
            </a>
            <br>
            <a href="https://ludomani.dk/" class="Modal-ItemLink">
              Center for Ludomani Odense
            </a>
            <br>
            <a href="http://www.ouh.dk/wm509394" class="Modal-ItemLink">
              Ludomaniklinikken
            </a>
            <br>
            <a href="https://www.tjele.com/andre-misbrug/ludomani/ludomani-i-arhus/" class="Modal-ItemLink">
              Behandlingscenter Tjele Aarhus
            </a>
            <br>
            <a href="https://ludomani.dk/" class="Modal-ItemLink">
              Center for Ludomani Århus
            </a>
            <br>
            <a href="https://ludomani.au.dk/" class="Modal-ItemLink">
              Forskningsklinikken for Ludomani
            </a>
            <br>
            <a href="https://www.tjele.com/andre-misbrug/ludomani/ludomani-aalborg/" class="Modal-ItemLink">
              Behandlingscenter Tjele Aalborg
            </a>
            <br>
            <a href="https://ludomani.au.dk/" class="Modal-ItemLink">
              Center for Ludomani Esbjerg
            </a>
            <br>
            <a href="https://www.tjele.com/andre-misbrug/ludomani/ludomani-kolding/" class="Modal-ItemLink">
              Behandlingscenter Tjele
            </a>
          </div>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Vi accepterer IKKE at mindreårige spiller
          </h4>
          <p class=" Modal-Text">
            Vi tillader ikke, at spillere under 18 år spiller. Dette fremgår tydeligt at vores
            <a href="https://son-email-direct.com/?skin=vw.dk&language=DA&name=tnc" class="Modal-ItemLink">Vilkår og
              Betingelser.</a>
          </p>
          <p class=" Modal-Text">
            Vi benytter både en automatisk og en manuel proces for at få bekræftet alderen på de kunder, der
            opretter en konto, og alle spillere under 18, som opretter en konto, får omgående lukket deres konto
            igen.
          </p>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Forebyg at mindreårige spiller
          </h4>
          <p class=" Modal-Text">
            Vi anbefaler, at forældre installerer et filtreringsprogram på deres computere, som blokerer visse
            hjemmesider og software. Dette gør det muligt at holde øje med, hvilke programmer dine børn ser og
            benytter sig af.
          </p>
          <p class=" Modal-Text">
            Vi anbefaler følgende filtreringsprogrammer:
            <a href="https://www.cyberpatrol.com/" class="Modal-ItemLink">Cyber Patrol</a>,
            <a href="https://www.gamblock.com/index.html" class="Modal-ItemLink">GamBlock</a> and
            <a href="https://www.netnanny.com/" class="Modal-ItemLink">Net Nanny</a>.
          </p>
          <p class=" Modal-Text">
            Vi anbefaler også følgende foranstaltninger:
          </p>
          <ul class="Modal-InnerList">
            <li class="Modal-InnerItem Modal-Text">Børn bør ikke sidde alene ved computeren uden opsyn.</li>
            <li class="Modal-InnerItem Modal-Text">Når de når den rigtige alder, skal børn lære, hvad det vil sige at
              spille, og hvordan det kan
              påvirke dem, hvis der ikke er styr på det.
            </li>
            <li class="Modal-InnerItem Modal-Text">Da man kan komme til at udtrykke følelser, når man spiller, bør du
              ikke
              lade børn se eller
              høre, når du spiller dine spil.
            </li>
            <li class="Modal-InnerItem Modal-Text">Hvis børn har adgang til din computer, anbefaler vi, at du
              deaktiverer
              muligheden for at huske
              adgangskoder.
            </li>
            <li class="Modal-InnerItem Modal-Text">Børn bør ikke have adgang til dit kreditkort eller andre
              betalingsoplysninger.
            </li>
            <li class="Modal-InnerItem Modal-Text">Du kan oprette en anden profil på din computer, som har passende
              software og adgang til hjemmesider, som dine børn kan benytte. Forældre, der spiller vores spil,
              bør benytte en anden profil.
            </li>
            <li class="Modal-InnerItem Modal-Text">Forældre bør lære sig selv om, hvilke farer der er ved at mindreårige
              spiller og opføre sig i henhold hertil.
            </li>
          </ul>
        </div>
        <div class="Modal-Item">
          <h4 class="Title Title--type-h4 Modal-SubTitle">
            Uautoriseret kontoadgang: Sådan undgår du det, og sådan opdager du det
          </h4>
          <p class="Modal-Text">
            Der er masser af underholdning her i dit yndlings online casino, og mens du kan være forvisset om,
            at vi gør alt, hvad vi kan, for at du får en sikker spilleoplevelse her, så er der nogen ting, du
            selv kan gøre for at gøre det endnu mere sikkert.
          </p>
          <p class="Modal-Text">
            Her er nogle enkle men effektive måder, hvorpå du kan forhindre autoriseret adgang til din konto:
          </p>
          <ul class="Modal-InnerList">
            <li class="Modal-InnerItem Modal-Text">Oplys aldrig dit brugernavn eller adgangskode til andre</li>
            <li class="Modal-InnerItem Modal-Text">Undlad at sætte hak i feltet "Husk adgangskode"</li>
            <li class="Modal-InnerItem Modal-Text">Sørg for, at du altid logger ud, når du er færdig med at spille</li>
            <li class="Modal-InnerItem Modal-Text">Oplys aldrig din e-mail, brugernavn eller adgangskode til andre</li>
            <li class="Modal-InnerItem Modal-Text">Undlad at sætte hak i feltet "Husk adgangskode” på din e-mailkonto
            </li>
            <li class="Modal-InnerItem Modal-Text">Sørg altid for at du logger ud, når du er færdig med at bruge din
              e-mailkonto
            </li>
            <li class="Modal-InnerItem Modal-Text">FVær ekstra opmærksom på ovenstående, når du benytter en delt
              computer
            </li>
          </ul>
          <p class="Modal-Text">
            Her er et par ting, som du bør være opmærksom på, hvis du har mistanke om, at andre har adgang til
            din konto:
          </p>
          <ul class="Modal-InnerList">
            <li class="Modal-InnerItem Modal-Text"> Saldo: hold øje med saldoen, når du logger ind og ud, og gør altid
              opmærksom på uoverensstemmelser
            </li>
            <li class="Modal-InnerItem Modal-Text">Spilhistorik: hold øje med de spil, du har spillet (du kan få en
              oversigt over de spil, du har spillet i hver session ved at kontakte support)
            </li>
            <li class="Modal-InnerItem Modal-Text">Kasse: vær opmærksom på, om du selv har lavet alle transaktioner</li>
            <li class="Modal-InnerItem Modal-Text">Kvitteringer: hold øje med dine kvitteringer for transaktioner i din
              e-mail indbakke, når du ved, at du ikke har spillet
            </li>
            <li class="Modal-InnerItem Modal-Text">Hvis du har mistanke om, at en anden har haft adgang til din konto,
              skal
              du straks kontakte support.
            </li>
          </ul>
        </div>
      </div>
    </modal>
  </nav>
</template>

<script>
import NavItem from '@/components/NavItem.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import LocalizedLink from '@/components/LocalizedLink.vue';
import auth from '@/mixins/auth';

export default {
  name: 'MainNav',
  components: {
    NavItem,
    LanguageSwitcher,
    LocalizedLink,
  },
  mixins: [auth],
  data() {
    return {
      documentIsScrolled: false,
      navIsOpen: false,
      topBarIsScrolled: false,
      navItems: [
        {
          name: this.$t('menu.promotions'),
          url: '/#promotions',
          icon: 'gift',
          iconDimensions: [19, 20],
          unsupportedLocales: ['se'],
        },
        {
          name: this.$t('menu.vip'),
          url: '/vip',
          icon: 'jewel',
          iconDimensions: [19, 18],
          unsupportedLocales: ['se'],
        },
        {
          name: this.$t('menu.aboutUs'),
          url: '/about-us',
          icon: 'about',
          iconDimensions: [19, 19],
        },
        {
          name: this.$t('menu.paymentMethods'),
          url: '/payment-methods',
          icon: 'payment',
          iconDimensions: [19, 16],
        },
        {
          name: this.$t('menu.faq'),
          url: '/faq',
          icon: 'faq',
          iconDimensions: [19, 19],
          unsupportedLocales: ['dk'],
        },
        {
          name: this.$t('menu.support'),
          url: '/support',
          icon: 'support',
          iconDimensions: [19, 19],
          unsupportedLocales: ['dk'],
        },
      ],
    };
  },
  computed: {
    localizedNavItems() {
      return this.navItems.filter((item) => {
        if (item.unsupportedLocales) return !item.unsupportedLocales.includes(this.$i18n.locale);
        return item;
      });
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll, { passive: true });
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.documentIsScrolled = window.scrollY > 0;
    },
    show() {
      this.$modal.show('modal');
    },
    hide() {
      this.$modal.hide('modal');
    },
  },
};
</script>

<style lang="scss">
.MainNav {
  position: sticky;
  top: 0;
  z-index: 2;

  &-Overlay {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(6, 8, 30, 0.9);
    z-index: 2;
  }

  &--bg {
    background: rgba(39, 43, 95, 0.7);
  }

  &-TopBar {
    position: relative;
    z-index: 1;
    max-width: 1248px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 16px;
  }

  &-Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-Nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 30px;
  }

  &-Toggle {
    width: 17px;
    margin-right: 15px;
    cursor: pointer;

    @media(min-width: $screen-s) {
      width: 27px;
      margin-right: 20px;
    }
  }

  &-Logo {
    flex-shrink: 0;
    width: 61px;
    vertical-align: middle;

    @media(min-width: $screen-s) {
      width: 197px;
    }

    @media(min-width: $screen-l) {
      width: 227px;
    }

    @media(min-width: $screen-xl) {
      width: 258px;
    }
  }

  &-Links {
    display: none;

    @media(min-width: $screen-l) {
      display: flex;
      margin-right: 20px;
    }
  }

  &-Link {
    margin-right: 15px;
    font-size: 14px;
    color: var(--color-text-main);
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    @media(min-width: $screen-xl) {
      margin-right: 20px;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &-Login {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 14px;
    line-height: 1.24;
  }

  &-Btn {
    margin-right: 10px;
    font-size: 10px;
    font-weight: 500;
    padding: 9px 14px;

    @media(min-width: $screen-s) {
      margin-right: 30px;
      padding: 13px 23px;
      font-size: 14px;
    }

    @media(min-width: $screen-l) {
      padding: 13px 30px;
    }

    &:last-child {
      margin-right: 0;
    }

    &--text {
      padding: 0;
    }
  }

  &-Language {
    margin-left: 10px;

    @media(min-width: $screen-m) {
      margin-left: 30px;
    }
  }

  .vm--modal {
    height: 95vh !important;
    background: url(~@/assets/img/modal_bg.png);
    background-size: cover;
    background-repeat: no-repeat;

    padding: 20px;
  }
}

.AsideMenu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  min-width: 308px;
  padding: 30px 0;
  background-color: var(--color-bg-nav);
  overflow-y: auto;
  scrollbar-width: thin;

  @media(min-width: $screen-xs) {
    min-width: 377px;
  }

  &-Logo {
    width: 61px;

    @media(min-width: $screen-s) {
      width: 167px;
    }

    @media(min-width: $screen-xl) {
      width: 212px;
    }
  }

  &-Header {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    padding: 0 16px;
  }

  &-Close {
    width: 20px;
    height: 20px;
    margin-right: 16px;
    cursor: pointer;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 20px;
      width: 3px;
      height: 20px;
      background-color: var(--color-text-main);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &-Form {
    padding: 16px;
    border-top: 1px solid rgba(39, 43, 95, 0.5);
    border-bottom: 1px solid rgba(39, 43, 95, 0.5);
  }

  &-List {
    width: 100%;
    padding-top: 18px;
  }

  .a-son-container .a-login-embeded {
    width: auto !important;
  }

  .a-control {
    height: auto !important;
    padding: 18px 16px 19px !important;
    color: var(--color-text-main) !important;
    background-color: var(--color-form) !important;
    border-radius: 8px !important;

    &::placeholder {
      color: var(--color-text-ghost) !important;
    }
  }

  .a-btn {
    padding: 14px !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.24;
    border-radius: 8px !important;
  }

  .a-btn-login {
    color: var(--color-main2) !important;
    background: transparent !important;
    border: 1px solid var(--color-main2) !important;

    &:hover {
      background: var(--color-main2) !important;
      color: var(--color-bg-nav) !important;
    }
  }

  .a-son-container .text-center {
    text-align: right !important;
  }
}

.MainNav-Responsible,
.MainNav-ResponsibleDK {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  color: var(--color-text-main);
  font-size: 8px;
  text-align: center;

  @media(min-width: $screen-s) {
    flex-direction: row;
  }

  @media(min-width: $screen-m) {
    font-size: 14px;
  }
}

.MainNav-ResponsibleAll {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MainNav-ResponsibleIcon {
  width: 20px;
  margin-right: 5px;
  cursor: pointer;

  @media(min-width: $screen-m) {
    width: 30px;
  }
}

.MainNav-ResponsibleLink {
  text-transform: lowercase;
  color: var(--color-main1);
  font-size: 8px;
  padding: 0 0 0 5px;
  margin-right: 5px;

  @media(min-width: $screen-m) {
    font-size: 14px;

    &:hover {
      opacity: .8;
      color: #FF6000;

      &:after {
        content: '';
        position: absolute;
        width: 95%;
        height: 2px;
        background-color: #FF6000;
        bottom: 3px;
        left: 5px;
        opacity: .8;
      }
    }
  }
}

.MainNav-ResponsibleSE {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  @media(min-width: $screen-s) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.MainNav-ResponsibleSELink {
  padding: 0;
  margin-right: 5px;
  height: 20px;
  cursor: pointer;

  @media(min-width: $screen-m) {
    height: 25px;
  }

  &:last-child {
    margin-right: 0;
    display: flex;
    align-items: baseline;
  }
}

.MainNav-ResponsibleSEIcon {
  height: 100%;
}

.MainNav-ResponsibleDK {
  flex-direction: row;
}

.MainNav-ResponsibleDKLink {
  margin-right: 9px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-text-main);
  font-size: 6.5px;
  padding: 0;

  @media(min-width: $screen-m) {
    margin-right: 15px;
    font-size: 14px;

    &:hover {
      color: var(--color-main1);
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 8px;
    right: -6px;
    top: 45%;
    transform: translate(0, -50%);
    background-color: var(--color-text-main);

    @media(min-width: $screen-xs) {
      height: 10px;
    }

    @media(min-width: $screen-m) {
      height: 14px;
      right: -8px;
    }
  }

  &:last-child {
    margin-right: 0;

    &:before {
      display: none;
    }
  }

  &:nth-child(2) {
    margin-right: 4px;

    &:before {
      display: none;
    }
  }
}

.MainNav-ResponsibleDKText {
  margin-right: 5px;
  font-size: 6.5px;

  @media(min-width: $screen-m) {
    font-size: 14px;
  }
}

.Modal-InnerList {
  list-style-type: disc;
  padding-left: 25px;
  color: var(--color-text-ghost);
  font-size: 14px;
}

.Modal-ItemLink {
  font-size: 14px;
  color: var(--color-text-ghost);

  &:hover {
    color: var(--color-main1);
  }
}

.Modal-SubTitle {
  font-size: 18px;
}

.Modal-Text {
  font-size: 14px;
  color: var(--color-text-ghost);
}

.MainNav-ResponsibleUKImg {
  max-width: 70px;
  width: 100%;

  @media(min-width: $screen-xs) {
    max-width: 110px;
  }

  @media(min-width: $screen-m) {
    max-width: 150px;
  }
}

.MainNav-Nav--de {
  margin-right: 5px;
  @media(min-width: $screen-s) {
    margin-right: 30px;
  }
}

.MainNav-Toggle--de {
  margin-right: 5px;

  @media(min-width: $screen-s) {
    margin-right: 20px;
  }
}

.MainNav-Btn--de {
  padding: 9px 9px;
  @media(min-width: $screen-s) {
    padding: 13px 14px;
  }
}

</style>
