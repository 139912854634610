<template>
<div v-if="Object.keys(languages).length" class="LanguageSwitcher" v-click-outside="onClickOutside">
  <div class="LanguageSwitcher-Outer" @click="isOpen = !isOpen">
    <img
      class="LanguageSwitcher-Icon"
      :src="require(`@/assets/img/${$i18n.locale}.png`)"
      alt="Country flag" />
    <div v-if="width > 460" class="LanguageSwitcher-ActiveLanguage">
      {{ $i18n.locale }}
    </div>
  </div>
  <transition name="slide-up">
    <div v-show="isOpen" class="LanguageSwitcher-Inner">
    <div
      v-for="(name, lang) in additionalLocales"
      :key="lang"
      class="LanguageSwitcher-AdditionalLanguage"
      @click="onSelectLang(lang)"
    >
      <img
        class="LanguageSwitcher-Icon"
        :src="require(`@/assets/img/${lang}.png`)"
        alt="Country flag"
      >
      <div class="LanguageSwitcher-Language">
        {{ name }}
      </div>
    </div>
  </div>
  </transition>
</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import createAuthScript from '@/mixins/createAuthScript';

export default {
  name: 'LanguageSwitcher',
  mixins: [createAuthScript],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState(['width', 'languages']),
    additionalLocales() {
      // eslint-disable-next-line max-len
      const languages = Object.entries(this.languages).filter((n) => n[0] !== this.$i18n.locale);
      return Object.fromEntries(languages);
    },
  },
  methods: {
    ...mapMutations(['setCountry']),
    ...mapActions(['getUserCountry', 'getLicensy']),
    onSelectLang(lang) {
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
        this.$router.replace({ params: { locale: lang } });
        this.isOpen = false;
        this.removeOldScript();
        this.createAuthScript();
      }
    },
    removeOldScript() {
      const oldScript = document.getElementById('authScript');
      const oldContainers = document.querySelectorAll('.a-son-container');
      const baseScript = document.querySelectorAll('script');
      baseScript.forEach((script) => {
        if (script.src.includes('base.js')) {
          script.remove();
        }
      });
      oldContainers.forEach((node) => {
        node.parentNode.removeChild(node);
      });
      oldScript.remove();
      const newContainer = document.createElement('div');
      newContainer.setAttribute('id', 'son_modal');
      document.body.appendChild(newContainer);
    },
    onClickOutside() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
.LanguageSwitcher {
  position: relative;
  cursor: pointer;

  &-Outer {
    display: flex;
    align-items: center;
  }

  &-Icon {
    flex-shrink: 0;
    width: 24px;

    @media(min-width: $screen-xs) {
      margin-right: 10px;
    }
  }

  &-Inner {
    position: absolute;
    top: 50px;
    right: 0;
    width: 130px;
    color: var(--color-text-ghost);
    background: var(--color-body);
    border-radius: 8px;

    &:after {
      content: '';
      position: absolute;
      top: -5px;
      right: 20px;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: var(--color-body);
    }
  }

  &-AdditionalLanguage {
    position: relative;
    z-index: 1;
    display: flex;
    padding: 16px 13px;
    border-bottom: 1px solid #1D2047;

    &:first-child {
      border-radius: 8px 8px 0 0;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }

    &:hover {
      color: var(--color-main1);
      background-color: var(--color-bg-hover);
    }
  }

  &-ActiveLanguage {
    width: 15px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--color-text-main);
  }

  &-Language {
    margin-left: 10px;
    font-size: 17px;
    font-weight: 400;
  }
}

</style>
