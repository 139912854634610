import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { apiHost, serviceHost, sids } from '@/config';
import i18n from '@/plugins/i18n';
import { searchInGamesRes, formatNumber } from '../utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    needsCookiesPopup: true,
    countryNotAllowed: false,
    skin: 'VegasWinner',
    platform: 'desktop',
    width: 0,
    games: [],
    isNothingFound: false,
    jackpots: [],
    gamesAreLoading: false,
    errors: {},
    languages: sids.default.languages,
    token: sids.default.token,
    country: '',
    license: 'MT',
    gameScript: '',
    paymentsLogos: [],
  },

  getters: {
    gamesLimited: (state) => (limit) => state.games.slice(0, limit),
    jackpotTotal: (state) => formatNumber(state.jackpots.total.amount),
  },

  mutations: {
    setNeedsCookiesPopup: (state, payload) => {
      state.needsCookiesPopup = payload;
    },
    setPaymentsLogos: (state, payload) => {
      state.paymentsLogos = payload;
    },
    setCountryNotAllowed: (state) => {
      state.countryNotAllowed = true;
    },
    setPlatform: (state, payload) => {
      state.platform = payload;
    },
    setWidth: (state, payload) => {
      state.width = payload;
    },
    gamesAreLoading: (state) => {
      state.gamesAreLoading = true;
    },
    gamesAreLoaded: (state) => {
      state.gamesAreLoading = false;
    },
    setGames: (state, payload) => {
      state.games = payload;
    },
    pushErrors: (state, payload) => {
      state.errors = {
        ...state.errors,
        payload,
      };
    },
    setJackpots: (state, payload) => {
      state.jackpots = payload;
    },
    setIsNothingFound: (state, isNothingFound) => {
      state.isNothingFound = isNothingFound;
    },
    setCountry: (state, country) => {
      state.country = country;
    },
    setSid: (state, payload) => {
      state.token = payload.token;
      state.skin = payload.skin;
      state.license = payload.license;
      if (payload.languages) {
        state.languages = payload.languages;
      } else {
        state.languages = {};
      }
    },
    setGameScript: (state, payload) => {
      state.gameScript = payload;
    },
  },

  actions: {
    async startGame({ state, commit }, gameID) {
      try {
        const res = await axios.get(`https://promos.safe-communication.com/funmode.php
`, {
          params: {
            appName: state.skin,
            langID: i18n.locale,
            gameID,
          },
        });
        commit('setGameScript', res.data);
      } catch (e) {
        commit('pushErrors', e);
      }
    },
    async getGames({
      state,
      commit,
    }, query) {
      commit('setIsNothingFound', false);
      commit('gamesAreLoading');
      try {
        // eslint-disable-next-line no-underscore-dangle
        const res = await axios.get(`${apiHost}/?liveCasinoOnly=true&country=${state.country}&platform=${state.platform}&${query}`);
        commit('setGames', res.data);
      } catch (e) {
        commit('pushErrors', e);
      } finally {
        commit('gamesAreLoaded');
      }
    },
    async searchGames({ commit }, query) {
      commit('setIsNothingFound', false);
      commit('gamesAreLoading');
      try {
        // eslint-disable-next-line no-underscore-dangle
        const res = await axios.get(`${apiHost}/?liveCasinoOnly=true`);
        const searched = searchInGamesRes(res.data, query);
        if (!searched.length) commit('setIsNothingFound', true);
        commit('setGames', searched);
      } catch (e) {
        commit('pushErrors', e);
      } finally {
        commit('gamesAreLoaded');
      }
    },
    async getJackpots({ commit }, query) {
      try {
        const res = await axios.get(`${serviceHost}/jackpots?${query}`);
        commit('setJackpots', res.data);
      } catch (e) {
        commit('pushErrors', e);
      }
    },
    async getUserCountry({ commit }) {
      try {
        const res = await axios.get('https://country.wake-app.net');
        commit('setCountry', res.data);
      } catch (e) {
        commit('pushErrors', e);
      }
    },
    async getPaymentsLogos({ commit }, query) {
      try {
        const { data } = await axios.get(`${serviceHost}/feeds/payments/single?${query}`);
        commit('setPaymentsLogos', data);
      } catch (e) {
        commit('pushErrors', e);
      }
    },
  },
});
